import { store } from '../store';

export const routes = [
    {
        path: "/",
        component: () => import("../pages/Layout.vue"),
        children: [
            {
                path: "",
                name: "Index",
                component: () => import("../pages/Inicial.vue"),
                beforeEnter: (to, from, next) => {
                    if (!localStorage.getItem("access_token")) {
                        store.dispatch('DELETE_USER');
                    }
                    next()
                },
            },
            {
                path: "/recuperar-senha",
                name: "RecuperarSenha",
                component: () => import("../pages/RecuperarSenha.vue"),
                beforeEnter: (to, from, next) => {
                    if (!localStorage.getItem("access_token")) {
                        store.dispatch('DELETE_USER');
                    }
                    next()
                },
            },
            // AREA LOGADA
            {
                path: "/home",
                name: "Home",
                component: () => import("../pages/calendario/Home.vue"),
                beforeEnter: (to, from, next) => {
                    if (!localStorage.getItem("access_token")) {
                        store.dispatch('DELETE_USER');
                        next('/')
                    } else {
                        next()
                    }
                },
            },
            {
                path: "/criar-cartao",
                name: "CriarCard",
                component: () => import("../pages/criarCard/CriarCard.vue"),
                beforeEnter: (to, from, next) => {
                    if (!localStorage.getItem("access_token")) {
                        store.dispatch('DELETE_USER');
                        next('/')
                    } else {
                        next()
                    }
                },
            },
            {
                path: "/resgatar-cartao",
                name: "ResgatarCard",
                component: () => import("../pages/resgatarCard/ResgatarCard.vue"),
                beforeEnter: (to, from, next) => {
                    if (!localStorage.getItem("access_token")) {
                        store.dispatch('DELETE_USER');
                        next('/')
                    } else {
                        next()
                    }
                },
            },
            {
                path: "/meus-cartoes",
                name: "MeusCards",
                component: () => import("../pages/meusCards/MeusCards.vue"),
                beforeEnter: (to, from, next) => {
                    if (!localStorage.getItem("access_token")) {
                        store.dispatch('DELETE_USER');
                        next('/')
                    } else {
                        next()
                    }
                },
            },
            {
                path: "/admin/usuarios",
                name: "UsersCriados",
                component: () => import("../pages/admin/usuarios/Listar.vue"),
                beforeEnter: (to, from, next) => {
                    if (!localStorage.getItem("access_token")) {
                        store.dispatch('DELETE_USER');
                        next('/')
                    } else {
                        next()
                    }
                },
            },
            {
                path: "/admin/cartoes",
                name: "AdminCardListar",
                component: () => import("../pages/admin/cartoes/Listar.vue"),
                beforeEnter: (to, from, next) => {
                    if (!localStorage.getItem("access_token")) {
                        store.dispatch('DELETE_USER');
                        next('/')
                    } else {
                        next()
                    }
                },
            },
            {
                path: "/admin/cartoes/:id",
                name: "AdminCardVisualizar",
                component: () => import("../pages/admin/cartoes/Visualizar.vue"),
                beforeEnter: (to, from, next) => {
                    if (!localStorage.getItem("access_token")) {
                        store.dispatch('DELETE_USER');
                        next('/')
                    } else {
                        next()
                    }
                },
            },
            {
                path: '/:pathMatch(.*)*',
                name: 'NotFound',
                component: () => import('../pages/NotFound.vue'),
            },
        ]
    },
]
